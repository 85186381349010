(function (w, d, searxng) {
  "use strict";
  const initTime = new Date().getTime();
  const COOKIE_VALUE_INDEX = 2;
  const REPORT_EVENT_ENDPOINT = 'https://g.givero.com/rtimp';
  let eventIndex = 0;

  function beaconSafe (endpoint, data) {
    try {
      const json = JSON.stringify({ entries: [data] });
      navigator.sendBeacon(endpoint, json);
    } catch (e) {
      try {
        console.error(e);
        const queryParams = Object.entries(data).map(([key, value]) => `${key}=${encodeURIComponent(value)}`).join('&');
        const url = `${endpoint}?${queryParams}`;
        new Image().src = url;
      } catch (e) { }
    }
  }

  function getCookieValue (cookieKey) {
    const match = document.cookie.match(new RegExp(`(^|;\\s*)${cookieKey}=([^;]+)`));
    return match ? match[COOKIE_VALUE_INDEX] : null;
  }

  function sendEvent (eventType, extra) {
    try {
      const timezoneOffset = new Date().getTimezoneOffset();
      let extraString = '';

      if (extra) {
        extraString = (typeof extra === "string") ? extra : JSON.stringify(extra, null, 4);
      }

      let timezone = "";

      try {
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      } catch (e) { }

      const eventTime = new Date().getTime();

      const reportObject = {
        sid: getCookieValue('uid_cross'), // uid
        d: w.location.host,
        a: eventType,
        ow: w.outerWidth,
        oh: w.outerHeight,
        iw: w.innerWidth,
        ih: w.innerHeight,
        tzof: timezoneOffset,
        tz: timezone,
        pxr: w.devicePixelRatio,
        cts: eventTime / 1000.0,
        cto: (eventTime - initTime),
        edx: eventIndex++,
        furl: d.location.href,
        ps: extraString
      };

      beaconSafe(REPORT_EVENT_ENDPOINT, reportObject);
    } catch (e) {
      console.error(e);
    }
  }

  searxng.sendEvent = sendEvent;
}(window, document,  window.searxng));